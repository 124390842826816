import { CommonModule } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs'
import { TranslateModule } from '@ngx-translate/core'
import { MatButtonModule } from '@angular/material/button'
import { ActivatedRoute, RouterModule, Router } from '@angular/router'
import { fromEvent, interval } from 'rxjs'
import { filter, switchMap, takeUntil, tap, throttle } from 'rxjs/operators'
import { Dal } from 'src/app/dal/dal'
import { CallbackFilterPipe } from 'src/app/pipes/callback-filter.pipe'
import { SnackbarService } from 'src/app/services/snackbar.service'
import { UtilitiesService } from 'src/app/services/utilities.service'
import { ComplianceReport, ComplianceReportEditedResult, ComplianceReportImage, EditType } from 'src/app/shared/api-structures/misc/reports'
import { IPosition } from 'src/app/shared/api-structures/mobile'
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy'
import { ExtendedProduct, ExtendedResult, MobileReportMarker, OverlayAdjustmentMode } from 'src/app/shared/interfaces'
import { LanguageService } from 'src/app/shared/services/language.service'
import { MainImageComponent } from '../compliance-main-image/main-image.component'
import { ImageButtonsComponent } from './image-buttons/image-buttons.component'
import { ImagesMapComponent } from './images-map.component'
import { ListProductComponent } from './list-product.component'
import { ComplianceReportService } from 'src/app/shared/services/complianceReport.service'
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator'
import { AccuracyBar } from './accuracy-bar'
import { MemoryStorageService } from 'src/app/services/memory-storage.service'
import { SettingsService } from 'src/app/shared/services/settings.service'

@Component({
  selector: 'overlay-adjustments',
  templateUrl: './overlay-adjustments.component.html',
  styleUrls: ['./overlay-adjustments.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    ImageButtonsComponent,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    ListProductComponent,
    MainImageComponent,
    MatButtonModule,
    CallbackFilterPipe,
    FormsModule,
    ImagesMapComponent,
    MatPaginatorModule,
    AccuracyBar,
    RouterModule
  ],
  standalone: true
})
export class OverlayAdjustmentsComponent extends AutoDestroy implements OnInit {
  @ViewChild('imageWrapInner') imageWrapInner: ElementRef;
  @ViewChild('dragger') dragger: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  data: ComplianceReport | null = null
  extendedProducts: ExtendedProduct[] = []
  extendedResults: ExtendedResult[] = []
  selectedImage: ComplianceReportImage | null = null
  selectedImagePath = ''
  selectedProduct: ExtendedProduct | null = null
  selectedAnnotation: MobileReportMarker | null = null
  reportId = ''
  annotadedFilter: ''
  allProductsFilter: ''
  allProductsFilterTimeout: any
  deletedResults: number[] = []
  saving = false
  markers: MobileReportMarker[] = []
  allProducts: ExtendedProduct[] = []
  imageRotation = 0

  imageMode: OverlayAdjustmentMode = 'view'
  imageHeight = 0
  imageWidth = 0
  imageTop = 0
  imageLeft = 0
  sub: any
  dragMode = false
  mousedown$
  mouseup$
  accuracy = 0
  accuracyByProduct: Record<string, [number, number]> = {}
  markerColors = {
    emptySpace: {
      color: 'rgba(255, 255, 255, 0.4)',
      borderColor: 'rgba(255, 255, 255, 1)',
    },
    inPlanogram: {
      borderColor: '#3ee150',
    },
    outPlanogram: {
      borderColor: '#ff7a01',
    },
    competitor: {
      borderColor: '#ff1412',
    },
    invador: {
      color: 'rgba(164, 164, 164, 0.4)',
      borderColor: 'black',
    },
    noise: {
      color: 'rgb(250 250 25,0.4)',
      borderColor: 'rgb(250 250 25)'
    }
  }

  constructor(
    route: ActivatedRoute,
    private utils: UtilitiesService,
    private languageService: LanguageService,
    private snackbarService: SnackbarService,
    private complianceReportService: ComplianceReportService,
    private dal: Dal,
    private memoryStorageService: MemoryStorageService,
    private settingsService: SettingsService
  ) {
    super()

    route.params.subscribe((params) => {
      this.reportId = params["reportId"];
    });
    const token = this.memoryStorageService.token
    if (token) {
      const lang = this.languageService.getLanguageFromToken(token)
      this.languageService.setLanguage(lang)
    }
  }

  removeBrokenImage(image: any) {
    console.warn(`Removing broken image: ${image.imagePath}`)
    this.data.images = this.data.images.filter((img) => img.imagePath !== image.imagePath)
  }
  

  ngOnInit(): void {
    this.paginator.pageSize = 25
    this.fetchReport()
  }

  ngAfterViewInit() {
    this.registerWheel()
    this.registerForDrag()
    this.registerForDragMove()
  }

  _replaceAt = (str: string, index: number, replacement: string) => {
    if (index < 0) return str
    return str.substring(0, index) + replacement + str.substring(index + replacement.length)
  }

  async fetchReport() {
    try {
      const data = await this.complianceReportService.getComplianceReport(this.reportId)
      this.data = data
      this.data.results = this.data.results
      this.data.products = this.data.products
      this.onImageSelected(data.images[0])
      this.reset()
    } catch (error) {
      console.error(error)
    }
  }

  productFilterCallback = (product: ExtendedProduct, val: string) => {
    if (!val) { return true }
    val = val.toLocaleLowerCase()
    return product.product.name.toLocaleLowerCase().includes(val) || product.product.id.toLocaleLowerCase().includes(val)
  }

  annotatedProductFilterCallback = (product: ExtendedProduct, val: string) => {
    const results = this.filterAnnotations(this.extendedResults, val)
    const prodInResults = results.find((res) => res.result.key === product.product.key)
    if (!prodInResults) { return false }
    return true
  }

  prepareProductsForSave() {
    const products = this.extendedProducts.map(p => {
      const facesCount = this.extendedResults.filter(r => {
        return r.deleted !== true && r.result.key === p.product.key
      }).length
      return { ...p.product, facesCount }
    })
    return products
  }

  async sendAnnotationToUnapproved() {
    const setting = await this.settingsService.getSettings()
    try {
      await this.cropAndUploadImagesToApprove(this.extendedResults.find(er => er.tempId === this.selectedAnnotation.id))
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('SentToUnapproved'))
      this.selectedAnnotation = null
    } catch (e) {
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('ErrorSendingToUnapproved'))
    }
  }

  async cropAndUploadImagesToApprove(er: ExtendedResult) {
    const dataUrl = this.complianceReportService.createCroppedImage(er, this.data)
    const name = er.result.id
    const planogramId = this.data?.planogramId || 'no_planogram'
    const croppedImage = await this.complianceReportService.uploadImageToApprove(dataUrl, name, planogramId)
    return { tempId: er.tempId, croppedImage }
  }

  async cropAndUploadImagesToCatalog(er: ExtendedResult) {
    const dataUrl = this.complianceReportService.createCroppedImage(er, this.data)
    const name = er.result.id
    const planogramId = this.data?.planogramId || 'no_planogram'
    const response = await this.complianceReportService.uploadImageToCatalog(dataUrl, name, planogramId)
    return { tempId: er.tempId, croppedImage: response.filePath }
  }

  onFilterAllProducts(event) {
    this.allProductsFilter = event.target.value
    clearTimeout(this.allProductsFilterTimeout)
    this.allProductsFilterTimeout = setTimeout(() => {
      this.paginator.pageIndex = 0
      this.fetchAllProducts()
    }, 500)
  }

  async calcAccuracy() {
    this.accuracy = 0
    this.accuracyByProduct = {}

    const index = this.data?.images.findIndex(img => img.imagePath === this.selectedImagePath)
    const annotations = this.data?.results.filter((o) => o.photoIndex === index)

    annotations.forEach(r => {
      if (!this.accuracyByProduct[r.key]) this.accuracyByProduct[r.key] = [0, 0]

      this.accuracyByProduct[r.key][0] += 1
      if (!r.editType || !['delete', 'new', 'edit'].includes(r.editType)) {
        this.accuracyByProduct[r.key][1] += 1
      }
    })

    let total = 0
    Object.values(this.accuracyByProduct).forEach(o => {
      total += (o[1] * 100) / o[0]
    })
    this.accuracy = total / Object.values(this.accuracyByProduct).length
  }

  async prepareResultForSave() {
    const editedResults: ComplianceReportEditedResult[] = []
    let croppedImages = []
    let iterator = 0
    const setting = await this.settingsService.getSettings()
    const sendToCatalog = setting.sendReportEditsToCatalog
    for (const er of this.extendedResults.filter(er => er.edited && !er.deleted)) {
      if (sendToCatalog) {
        this.cropAndUploadImagesToCatalog(er)
      } else {
        this.cropAndUploadImagesToApprove(er)
      }

      if (iterator === 10 || iterator === this.extendedResults.length - 1) {
        const batch = await Promise.all(croppedImages)
        croppedImages = [...croppedImages, ...batch]
        iterator = 0
      }
    }

    for (const er of this.extendedResults) {
      let croppedUrl = ''

      let editType: EditType = er.result.editType || 'original'
      if (er.isNew) { editType = 'new' }
      else if (er.deleted) { editType = 'delete' }
      // we save the original only if we replace/edit it
      else if (er.original) { editType = 'edit' }

      const editedResult: ComplianceReportEditedResult = {
        confidence: er.result.confidence,
        editType: editType,
        id: er.result.id,
        isCompetitor: false,
        isNoise: false,
        isInvasor: false,
        isUserEdit: true,
        location: { top: 0, left: 0, bottom: 0, right: 0 },
        locationOnOriginal: er.result.position,
        photoIndex: er.result.photoIndex,
        title: er.result.title,
        originalRecognition: er.result.originalRecognition ?? er.result.id,
        shelf: er.result.shelf
      }

      if (er.edited) {
        croppedUrl = croppedImages.find(c => c.tempId === er.tempId)?.croppedImage ?? ''
        editedResult.isBackofficeEdit = true
      }

      if (croppedUrl) {
        editedResult.croppedUrl = croppedUrl
      }

      if (er.original) {
        editedResult.originalRecognition = er.original.id
        editedResult.locationOnOriginal = er.original.position
      }

      editedResults.push(editedResult)
    }
    return editedResults
  }

  async save() {
    this.saving = true
    try {
      const editedResults = await this.prepareResultForSave()
      const products = await this.prepareProductsForSave()
      await this.complianceReportService.updateMobileReportResults(this.reportId, editedResults, products)
      await this.fetchReport()
      this.snackbarService.openSnackBar(2000, this.languageService.translateSync('EditedSuccessfully'))
    } catch (err) {
      console.error(err)
    }
    this.saving = false
  }

  changeMode(mode: OverlayAdjustmentMode) {
    this.selectedAnnotation = null
    if (this.imageMode === mode) { return this.imageMode = 'view' }
    this.imageMode = mode
    if (mode === 'replace') { this.selectedProduct = null }
  }

  reset() {
    const data = this.utils.deepCopy(this.data)
    this.resetImagePosition()
    this.extendedResults = data.results.map((result, i) => ({ result, deleted: result.editType === 'delete', tempId: i.toString() }))
    this.extendedProducts = data.products.sort((a, b) => b.facesCount - a.facesCount).map((product) => {
      const color = product.isNonPlanogram ? 'rgb(180,180,180)' : this.utils.strToColor(product.key)
      return { product, visible: true, color }
    })
    this.selectedProduct = null
    this.selectedAnnotation = null
    this.changeMode('view')
    this.setMarkers()
  }

  pushProductToAnnotations(product: ExtendedProduct) {
    const exists = this.extendedProducts.find(p => p.product.key === product.product.key)
    if (!exists) {
      this.extendedProducts.push(product)
    }
  }
  // changing actions
  addResult(event: IPosition) {
    if (!this.selectedProduct) {
      alert(this.languageService.translateSync('PleaseSelectAProductFirst'))
      return
    }

    const overlap = this.filterAnnotations(this.extendedResults, this.selectedImagePath).find(er => this.utils.rectOverlap(er.result.position, event))
    if (overlap) {
      alert(this.languageService.translateSync('TheSelectedAreaOverleapsWithAnotherFace'))
      return
    }
    this.pushProductToAnnotations(this.selectedProduct)
    const index = this.data?.images.findIndex(img => img.imagePath === this.selectedImage.imagePath)
    const tempId = this.extendedResults.length.toString()
    const newResult: ExtendedResult = {
      result: {
        confidence: 1,
        id: this.selectedProduct.product.id,
        family: this.selectedProduct.product.family,
        position: event,
        key: this.selectedProduct.product.key,
        title: this.selectedProduct.product.name,
        name: this.selectedProduct.product.name,
        photoIndex: index,
        resType: 'edited',
      },
      tempId: tempId,
      isNew: true,
      deleted: false,
      edited: true
    }
    this.extendedResults = [...this.extendedResults, newResult]
    this.changeFacesCount(this.selectedProduct, 1)
    this.setMarkers()
    this.selectedAnnotation = this.markers.find(m => m.id === tempId)

  }

  replaceResult(result: ExtendedResult) {
    if (!this.selectedProduct) {
      alert(this.languageService.translateSync('PleaseSelectAProductFirst'))
      return
    }

    this.pushProductToAnnotations(this.selectedProduct)
    const resultToReplace: ExtendedResult = {
      ...result,
      original: this.utils.deepCopy(result.result),
      result: {
        confidence: result.result.confidence,
        id: this.selectedProduct.product.id,
        family: this.selectedProduct.product.family,
        position: result.result.position,
        key: this.selectedProduct.product.key,
        title: this.selectedProduct.product.name,
        name: this.selectedProduct.product.name,
        photoIndex: result.result.photoIndex,
        originalRecognition: result.result.originalRecognition ?? result.result.id,
        resType: 'edited'
      },
      edited: true
    }
    this.changeFacesCount(this.selectedProduct, 1)
    const prevProduct = this.extendedProducts.find(ep => ep.product.key === result.result.key)
    if (prevProduct) {
      this.changeFacesCount(prevProduct, -1)
    }
    this.extendedResults = this.extendedResults.map((r) => r.tempId === result.tempId ? resultToReplace : r)
    this.setMarkers()
  }

  changeResult(marker: MobileReportMarker) {
    if (marker.type !== 'ExtendedResult') { return }

    const result = marker.originalElement as ExtendedResult
    result.result.position = marker.position
    const overlap = this.filterAnnotations(this.extendedResults, this.selectedImagePath).filter(er => er.tempId !== result.tempId).find(er => this.utils.rectOverlap(er.result.position, result.result.position))
    if (overlap) {
      alert(this.languageService.translateSync('TheSelectedAreaOverleapsWithAnotherFace'))
      this.extendedResults = this.utils.deepCopy(this.extendedResults)
      this.setMarkers()
      return
    }

    this.extendedResults = this.extendedResults.map((r) => r.tempId === result.tempId ? result : r)
    this.setMarkers()
  }

  changeFacesCount(extendedProduct: ExtendedProduct, number: number) {
    let facesCount = extendedProduct.product.facesCount ?? 0
    // number can be negative
    facesCount += number
    if (facesCount < 0) { facesCount = 0 }

    extendedProduct.product.facesCount = facesCount
    this.extendedProducts = [...this.extendedProducts]
  }

  removeResult(result: ExtendedResult) {
    result.deleted = true
    result.edited = true
    const prod = this.extendedProducts.find((p) => p.product.key === result.result.key)
    this.changeFacesCount(prod, -1)
    this.setMarkers()
  }
  // end changing actions
  markSelected(marker: MobileReportMarker) {
    if (marker.type !== 'ExtendedResult') return

    const result = marker.originalElement as ExtendedResult
    if (this.imageMode === 'replace') {
      this.replaceResult(result)
    } else if (this.imageMode === 'remove') {
      this.removeResult(result)
    } else if (this.imageMode === 'view' || this.imageMode === 'add') {
      if (this.selectedAnnotation?.id !== marker.id) {
        this.scrollToProduct(result.result.key)
        this.selectedAnnotation = marker
      } else {
        this.selectedAnnotation = null
      }
    }
  }

  productSelected(product: ExtendedProduct) {
    if (this.selectedProduct?.product.key === product.product.key) {
      this.selectedProduct = null
      return
    } else {
      this.selectedProduct = product
    }
  }

  onImageSelected(image: ComplianceReportImage) {
    setTimeout(() => {
      // this is because annotation are loaded before image changes so we need to wait a bit
      this.selectedImagePath = image?.imagePath
      this.setMarkers()
    }, 200)
    this.selectedImage = image
    this.resetImagePosition()
  }

  findNearImage(dir: 'left' | 'right' | 'top' | 'bottom') {
    const xy = this.selectedImage?.photoXY
    if (!xy) { return false }

    if (dir === 'left') {
      return this.data?.images.find(img => img.photoXY.x === xy.x - 1 && img.photoXY.y === xy.y)
    }
    if (dir === 'right') {
      return this.data?.images.find(img => img.photoXY.x === xy.x + 1 && img.photoXY.y === xy.y)
    }
    if (dir === 'top') {
      return this.data?.images.find(img => img.photoXY.x === xy.x && img.photoXY.y === xy.y - 1)
    }
    if (dir === 'bottom') {
      return this.data?.images.find(img => img.photoXY.x === xy.x && img.photoXY.y === xy.y + 1)
    }
  }

  moveImage(dir: 'left' | 'right' | 'top' | 'bottom') {

    const image = this.findNearImage(dir)

    if (image) {
      this.onImageSelected(image)
    }
  }

  filterAnnotations(annotations: ExtendedResult[], imagePath: string) {
    const index = this.data?.images.findIndex(img => img.imagePath === imagePath)
    const filterd = annotations?.filter((m) => {
      if (m.result.photoIndex !== index) { return false }
      if (m.deleted) { return false }
      const prod = this.extendedProducts.find(ap => ap.product.key === m.result.key)
      return prod?.visible
    })
    return filterd
  }

  toggleVisibility(product: ExtendedProduct) {
    product.visible = !product.visible
    this.setMarkers()
  }

  async fetchAllProducts() {
    const res = await this.dal.searchProducts(this.paginator.pageIndex, this.paginator.pageSize, this.allProductsFilter ? [this.allProductsFilter] : null, { minimumImages: "1" }, false, 'name', 'asc', true)
    this.allProducts = res.items.map(p => {
      const planogramProduct = this.extendedProducts.find(ep => ep.product.key === p.productId)
      const defaultCatalog = p.imageCatalogs?.find(c => c.id !== 'noise' && c.id !== 'invasor')
      const newId = `${p.productId}_${defaultCatalog?.id}`
      const ep: ExtendedProduct = {
        product: {
          facesCount: planogramProduct?.product?.facesCount ?? 0,
          plannedFaces: planogramProduct?.product?.plannedFaces ?? 0,
          family: p.family,
          id: planogramProduct?.product?.id ?? newId,
          key: p.productId,
          name: p.name,
          imageUrl: p.thumbnailUrl,
          ean: p.ean ?? p.productId,
          category: p.category,
          isNonPlanogram: planogramProduct?.product?.isNonPlanogram ?? true,
        },
        color: planogramProduct?.color ?? 'rgb(180,180,180)',
        visible: true
      }
      return ep
    })
    this.paginator.length = res.totalProducts
  }

  setMarkers() {
    const index = this.data?.images.findIndex(img => img.imagePath === this.selectedImagePath)
    const annotations = this.filterAnnotations(this.extendedResults, this.selectedImagePath)
    // TODO add index after noam creates
    const emptySpaces = this.data?.emptySpaces?.filter(es => es.photoIndex === index) || []
    const shelves = this.data?.shelves
    const markers: MobileReportMarker[] = []

    shelves.forEach((a, index) => {
      const marker: MobileReportMarker = {
        id: "shelf_" + index.toString(),
        position: a.locationOnOriginal,
        type: 'Shelf',
        tag: 'Shelf',
        tooltipTxt: "shelf_" + index.toString(),
        originalElement: a,
      }
      markers.push(marker)
    })

    emptySpaces.forEach((es, index) => {
      const marker: MobileReportMarker = {
        id: `emptySpace_${index}`,
        position: es,
        type: 'EmptySpace',
        color: this.markerColors.emptySpace.color,
        borderColor: this.markerColors.emptySpace.borderColor,
        tag: 'Empty Space',
        tooltipTxt: 'Empty Space',
        originalElement: es,
      }
      markers.push(marker)
    })

    annotations.forEach((a) => {
      const marker: MobileReportMarker = {
        id: a.tempId,
        position: a.result.position,
        type: 'ExtendedResult',
        tag: a.result.key,
        tooltipTxt: a.result.name + " - *SHELF(" + a.result.shelf + ")*",
        originalElement: a,
      }
      const product = this.data.products.find(p => p.key === a.result.key)
      if (product && !product.isNonPlanogram) {
        marker.color = this.utils.strToColor(marker.tag ?? '', 0.4)
        marker.borderColor = this.utils.strToColor(marker.tag ?? '', 1)
      }
      markers.push(marker)
    })

    this.markers = markers.filter(m => m.position)
    if (this.markers.length < markers.length) {
      console.error('Some markers are missing position')
    }
    this.calcAccuracy()
  }

  tabChanged(event: MatTabChangeEvent) {
    if (event.index === 1 && !this.allProducts.length) {
      this.fetchAllProducts()
    }
  }

  // zoom
  resetImageSize() {
    if (this.imageWrapInner) {
      this.imageHeight = this.imageWrapInner.nativeElement.offsetHeight
      this.imageWidth = this.imageWrapInner.nativeElement.offsetWidth
    }
  }

  imageZoomIn() {
    if (this.imageHeight * 1.05 > this.imageWrapInner.nativeElement.offsetHeight * 5) {
      return
    }
    this.imageHeight = this.imageHeight * 1.05
    this.imageWidth = this.imageWidth * 1.05
  }

  imageZoomOut() {
    if (this.imageHeight * 0.95 < this.imageWrapInner.nativeElement.offsetHeight) {
      this.resetImageSize()
      return
    }
    this.imageHeight = this.imageHeight * 0.95
    this.imageWidth = this.imageWidth * 0.95
  }

  registerForDragMove() {
    const mouseDownStream$ = fromEvent(this.dragger.nativeElement, 'mousedown');
    const mouseMoveStream$ = fromEvent(this.dragger.nativeElement, 'mousemove');
    const mouseUpStream$ = fromEvent(window, 'mouseup');
    let lastMouseX = 0
    let lastMouseY = 0
    let mouseDownEvent = 0

    this.subscriptions.push(mouseDownStream$.pipe(
      tap((event: MouseEvent) => {
        mouseDownEvent = event.button
        lastMouseX = event.offsetX
        lastMouseY = event.offsetY
      }),
      filter(() => mouseDownEvent === 0),
      switchMap(() => mouseMoveStream$.pipe(
        throttle(() => interval(5)),
        tap((event: MouseEvent) => {
          const mousex = event.offsetX
          const mousey = event.offsetY
          this.imageTop = this.imageTop + (mousey - lastMouseY)
          this.imageLeft = this.imageLeft + (mousex - lastMouseX)
          lastMouseX = mousex
          lastMouseY = mousey
        }),
        takeUntil(mouseUpStream$)
      ))
    ).subscribe())
  }

  registerWheel() {
    let scroll$ = fromEvent(this.imageWrapInner.nativeElement, 'wheel');
    this.subscriptions.push(scroll$.subscribe((e: any) => {
      e.preventDefault()
      if (e.deltaY > 0) {
        this.imageZoomOut()
      } else if (e.deltaY < 0) {
        this.imageZoomIn()
      }
    }))
  }

  registerForDrag() {
    let keydown$ = fromEvent(document, 'keydown')
    let keyup$ = fromEvent(document, 'keyup')
    this.subscriptions.push(keydown$.subscribe((e: any) => {
      if ([17, 224, 91, 93].includes(e.keyCode)) {
        this.dragMode = true
      }
    }))
    this.subscriptions.push(keyup$.subscribe((e: any) => {
      this.dragMode = false
    }))
  }

  resetImagePosition() {
    this.resetImageSize()
    this.imageTop = 0
    this.imageLeft = 0
  }

  rotateImage() {
    this.imageRotation = (this.imageRotation + 90) % 360
  }

  scrollToProduct(productKey: string) {
    let el = document.getElementById(`ap_${productKey}`)
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" });
    }
  }
}
