import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplianceReportImage } from 'src/app/shared/api-structures/misc/reports';

@Component({
  selector: 'images-map',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div *ngIf="imageLocations && imageLocations[0]?.photoXY" class="container">
      <div class="rect" *ngFor="let il of imageLocations"
      (click)="select.emit(il)"
      [ngClass]="{'selected': il.imagePath === selectedImage.imagePath}"
      [ngStyle]="{'grid-column':il.photoXY.x+1,'grid-row': il.photoXY.y+1}">
      </div>
    </div>
  `,
  styles: [`
    .container {
      background: #d6d6d6;
      border-radius: 5px;
      padding: 4px;
      display: grid;
    }
    .rect {
      border-radius: 5px;
      width: 20px;
      height: 40px;
      background: #919191;
      margin: 4px;
      cursor: pointer;
    }
    .rect.selected {
      background: #fff;
    }
  `]
})

export class ImagesMapComponent implements OnInit {

  constructor() { }

  toShow = false
  @Input() imageLocations: ComplianceReportImage[] = [];
  @Input() selectedImage: ComplianceReportImage | null = null;

  @Output() select = new EventEmitter<string>()

  ngOnInit(): void {
  }

}
